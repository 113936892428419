import { useCallback, useEffect, useState } from "react";
import "./App.css";

import { ConnectWallet, useWallet } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import getEthRecoveryId from "utils/getEthRecoveryId";

const TEMP_MESSAGE =  JSON.stringify({ data: 'hello world' });

function App() {
  const wallet = useWallet();

  const [signedMessage, setSignedMessage] = useState("");
  const [messageHash, setMessageHash] = useState('');

  console.log({ wallet });

  useEffect(() => {
    if (!wallet) {
      return;
    }

    const storeSignedMessage = async () => {
      // Generate the keccak256 hash from the message
      const _messageHash = ethers.utils.id(TEMP_MESSAGE);

      // Get the message hash signed
      const signedMessageHash = await wallet.signMessage(_messageHash);

      setSignedMessage(signedMessageHash);
      setMessageHash(_messageHash);
    };

    storeSignedMessage();
  }, [wallet]);

  const onSend = useCallback(async () => {
    if (!signedMessage) {
      return;
    }

    if (!wallet) {
      return;
    }

    const walletAddress = await wallet.getAddress();

    const recoveryId = getEthRecoveryId(signedMessage);

    fetch("http://159.89.127.99:6969/relayMessage", {
      method: "POST",
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        signed_message: {
          ethereum_message_signer: walletAddress,
          // recovery_id: recoveryId,
          message_hash: messageHash,
          signature: signedMessage,
        }
      }),
    });
  }, [signedMessage]);

  return (
    <div className="App">
      <ConnectWallet />

      <div style={{ height: 80 }} />

      <button onClick={onSend}>Send msg</button>
    </div>
  );
}

export default App;
