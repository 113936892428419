export default function getEthRecoveryId (signature: string) {
  if (!signature) {
    return -1;
  }

  // Convert message hash and signature to Buffers
  const signatureBuffer = Buffer.from(signature.slice(2), 'hex');

  // Extract r, s, & v from the signature buffer
  const v = signatureBuffer[64] - 27;

  return v;
}